import React from "react";
import "./Project.css";

import p1img from "./images/p1img.jpg";
import p2img from "./images/p2img_displacement.png";
import pCode from "./images/Code.png";
import pDeom from "./images/demo.png";

const Project = () => {
  return (
    <div className="masterProject">
      <h1 style={{ paddingTop: "25px" }}>My Recent Work</h1>
      <div className="projectContainer">
        <div className="pcItem">
          <img className="projectThumb" src={p1img} alt="Project Thumb"></img>
          <h1>Songs MERN</h1>
          <div className="TechStack">
            <h4 className="tech">React TS</h4>
            <h4 className="tech">Node JS</h4>
            <h4 className="tech">Express JS</h4>
            <h4 className="tech">Mongodb</h4>
            <h4 className="tech">Docker</h4>
            <h4 className="tech">Rest Api</h4>
            <h4 className="tech">Redux</h4>
            <h4 className="tech">Responsive</h4>
          </div>
          <div className="projectDescription">
            <h3>Description</h3>
            <pre className="desParagraph">
              A Full Stack MERN Project, With REST API able to create, list,
              update and remove songs.
            </pre>
          </div>
          <div className="cardFooter">
            <div className="cfDivCode">
              <button
                className="btnCode"
                onClick={() => {
                  alert("Contact Me");
                }}
              >
                Code
              </button>
              <img className="icon" src={pCode} alt="icon"></img>
            </div>
            <div className="cfDivDemo">
              <button
                className="btnDemo"
                onClick={() => {
                  window.open("https://song-frontend-responsive.vercel.app/");
                }}
              >
                Demo
              </button>
              <img className="icon" src={pDeom} alt="icon"></img>
            </div>
          </div>
        </div>
        {/* project 2 */}
        <div className="pcItem">
          <img className="projectThumb" src={p2img} alt="Project Thumb"></img>
          <h1>Lemenor </h1>
          <div className="TechStack">
            <h4 className="tech">React JS</h4>
            <h4 className="tech">Firebase Authentication</h4>
            <h4 className="tech">Firebase Storage</h4>
            <h4 className="tech">Firebase Cloud Firestore</h4>
            <h4 className="tech">3rd Party API</h4>
            <h4 className="tech">leaflet Map Library</h4>
          </div>
          <div className="projectDescription">
            <h3>Description</h3>
            <pre className="desParagraph">
              Le Menor is a web app dedicated to connect displaced people with
              volunters/NGos/Gov in order to change there life
            </pre>
          </div>
          <div className="cardFooter">
            <div className="cfDivCode">
              <button
                className="btnCode"
                onClick={() => {
                  alert("Contact Me");
                }}
              >
                Code
              </button>
              <img className="icon" src={pCode} alt="icon"></img>
            </div>
            <div className="cfDivDemo">
              <button
                className="btnDemo"
                onClick={() => {
                  window.open(
                    "https://le-menor-users-9iitgav35-kirubeldevs-projects.vercel.app/"
                  );
                }}
              >
                Demo
              </button>
              <img className="icon" src={pDeom} alt="icon"></img>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="pcItem">3</div>
      </div>
    </div>
  );
};

export default Project;
