import React from "react";
import "./About.css";

import pimg from "./images/removebg.png";

const About = () => {
  return (
    <div className="aboutMaster">
      <div className="about_LDiv">
        <h1 className="anHeader">About Me</h1>
        <div className="aboutContent">
          <h2 className="aboutH">Hi, I'm Kirubel Jalleta!</h2>
          <pre>
            Passionate problem-solver, code enthusiast, and recent computer
            science graduate.
          </pre>
        </div>
        {/*  */}
        <div className="aboutContent">
          <h2 className="aboutH">My Journey</h2>
          <pre className="aboutPre">
            Ever since I wrote my first line of code, I've been captivated by
            the world of technology and its limitless possibilities. My journey
            through computer science has been an exciting one. I graduated from
            "Unity University" with a Bachelor's degree in Computer Science in
            "2023".
          </pre>
        </div>
        {/*  */}
        <div className="aboutContent">
          <h2 className="aboutH">Beyond the Screen</h2>
          <pre className="aboutPre">
            Outside the coding realm, you can often find me immersed in the
            world of literature or hiking in the great outdoors. These interests
            provide me with a balanced perspective and a fresh source of
            creativity.
          </pre>
        </div>
        {/*  */}
        <div className="aboutContent">
          <h2 className="aboutH">Let's Connect</h2>
          <pre className="aboutPre">
            I'm excited to connect with like-minded individuals, collaborate on
            exciting projects, and continue my journey in the ever-evolving
            field of computer science. If you'd like to chat, explore my work,
            or discuss potential collaborations, please don't hesitate to get in
            touch.
          </pre>
        </div>
        {/*  */}
        <pre className="preThanks">
          Thanks for visiting, and I look forward to connecting with you!
        </pre>
        <button
          className="btnCV"
          onClick={() => {
            window.open(
              "https://drive.google.com/file/d/1k7anDgZUIFT-leDouOAnnFfPeeO4oHy2/view?usp=sharing"
            );
          }}
        >
          Download CV
        </button>
      </div>
      <div className="about_RDiv">
        <img className="pimg" src={pimg} alt="img"></img>
      </div>
    </div>
  );
};

export default About;
