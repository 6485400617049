import React from "react";
import "./Contact.css";

import cimg from "./images/undraw_personal_text.svg";

const Contact = () => {
  return (
    <div className="contactMaster">
      <div className="Form">
        <iframe
        title="google_cntact_form"
        className="gform"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfBM60RaPgm4tJImtH7rjZHTLvAXjG1Ppi8Y_JUnUUFZPsIDg/viewform?embedded=true"
          width="640"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
      <div className="contactImage">
        <img className="contactimg" src={cimg} alt="img"></img>
      </div>
    </div>
  );
};

export default Contact;
