import React, { useState } from "react";
import "./Navbar.css";

import { Link } from "react-router-dom";

import img from "./images/Menu.png";

const Navbar = () => {
  const [modalState, setmodalState] = useState(false);

  return (
    <div className="masterNavbar">
      <>
        <nav className="navbar">
          <ul className="navbarUL">
            <li className="li">
              <Link to="/">Home</Link>
            </li>
            <li className="li">
              <Link to="/skill">Skill</Link>
            </li>
            <li className="li">
              <Link to="/project">Projects</Link>
            </li>
            <li className="li">
              <Link to="/resume">Resume</Link>
            </li>
            <li className="li">
              <Link to="/about">About</Link>
            </li>
            <li className="li">
              <Link to="/contact">Contact</Link>
            </li>

            <img
              className="sandwith"
              alt="menu"
              src={img}
              onClick={(e) => {
                if (modalState === false) {
                  setmodalState(true);
                } else {
                  if (modalState === true) {
                    setmodalState(false);
                  }
                }
              }}
            ></img>
          </ul>
        </nav>
        {/* Modal Navigation bar */}
        {modalState ? (
          <div className="modal">
            <div className="modalNavbar">
              <li className="mli">
                <Link className="mlink" to="/">
                  Home
                </Link>
              </li>
              <li className="mli">
                <Link className="mlink" to="/skill">
                  Skill
                </Link>
              </li>
              <li className="mli">
                <Link className="mlink" to="/project">
                  Projects
                </Link>
              </li>
              <li className="mli">
                <Link className="mlink" to="/projec">
                  Resume
                </Link>
              </li>
              <li className="mli">
                <Link className="mlink" to="/about">
                  About
                </Link>
              </li>
              <li className="mli">
                <Link className="mlink" to="/contact">
                  Contact
                </Link>
              </li>
              {/*  */}
              <button
                className="btn_close"
                onClick={(e) => setmodalState(false)}
              >
                Close
              </button>
            </div>
          </div>
        ) : null}
        {/*  */}
      </>
    </div>
  );
};

export default Navbar;
