import React from "react";
import "./Layout.css";

// pages
import Landing from "../landing/Landing";
// import About from "../about/About";
// import Project from "../project/Project";
// import Skill from "../skill/Skill";
// import Contact from "../contact/Contact";

const Layout = () => {
  return (
    <div>
      <Landing />
      {/* <Skill />
      <Project />
      <About />
      <Contact /> */}
    </div>
  );
};

export default Layout;
