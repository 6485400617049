import React from "react";
import "./Footer.css";

import icon_telegram from "./images/icon_telegram.png";
import icon_linkedin from "./images/icon_linkedin.png";
import icon_github from "./images/icon_github.png";
import icon_gmail from "./images/icon_gmail.png";

const Footer = () => {
  return (
    <div className="footerMaster">
      <div className="LFooter">
        <img
          className="icon"
          alt="icon"
          onClick={() => {
            window.open(
              "https://mail.google.com/mail/u/2/#inbox?compose=new"
            );
          }}
          src={icon_gmail}
        ></img>
        <img
          className="icon"
          alt="icon"
          onClick={() => {
            window.open(
              "https://www.linkedin.com/in/kirubel-jalleta-7bb117233"
            );
          }}
          src={icon_linkedin}
        ></img>
        <img
          className="icon"
          alt="icon"
          onClick={() => {
            window.open("https://t.me/kiru_byte");
          }}
          src={icon_telegram}
        ></img>
        <img
          className="icon"
          alt="icon"
          onClick={() => {
            window.open("https://github.com/");
          }}
          src={icon_github}
        ></img>
      </div>
      <div className="MFooter">
        <h1>© 2023 by Kirubel Jalleta</h1>
      </div>
      <div className="RFooter">
        <pre className="footerpre" style={{ color: "white" }}>
          Living, learning, & leveling up one day at a time.
        </pre>
      </div>
    </div>
  );
};

export default Footer;
