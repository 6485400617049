import React from "react";
import "./App.css";

import { Routes, Route } from "react-router-dom";

// Componets
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
//pages
import Project from "./components/pages/project/Project";
import Layout from "./components/pages/layout/Layout";
import Skill from "./components/pages/skill/Skill";
import About from "./components/pages/about/About";
import Contact from "./components/pages/contact/Contact";
import Resume from "./components/pages/resume/Resume";
//
function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="Container">
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/project" element={<Project />} />
          <Route path="/skill" element={<Skill />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
