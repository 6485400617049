import React from "react";
import "./Landing.css";

import codeimg from "./images/undraw_programming.svg";
import code from "./images/codelogo.png";

const Landing = () => {
  return (
    <div className="masterLanding">
      <div className="landingContaindrer">
        <div className="hero">
          <h3>Hey, I'm</h3>
          <div class="wrapper">
            <svg>
              <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                Kirubel Jalleta
              </text>
            </svg>
          </div>
          <pre className="intro">
            computer science graduate on a mission to create <bre></bre>
            elegant, efficient software that makes a difference.
          </pre>
          <img className="codelogo" src={code} alt="logo"></img>
        </div>
        {/*  */}
        <div className="imagediv">
          <img className="codeimg" src={codeimg} alt="logo"></img>
        </div>
      </div>
    </div>
  );
};

export default Landing;
