import React from "react";
import "./Resume.css";

const Resume = () => {
  return (
    <div className="resumeMaster">
      {/* <iframe
        src="https://drive.google.com/file/d/1k7anDgZUIFT-leDouOAnnFfPeeO4oHy2/view?usp=sharing"
        width="640"
        height="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe> */}
      {/*  */}
      <iframe
      title="googledoc"
        className="iframe"
        src="https://drive.google.com/file/d/1k7anDgZUIFT-leDouOAnnFfPeeO4oHy2/preview"
        // width="640"
        // height="480"
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default Resume;
