import React from "react";
import "./Skill.css";

import { Parallax } from "react-scroll-parallax";

import skillimg from "./image/skill.jpg";

import Zoom from "react-reveal/Zoom";

// Icons
import html from "./image/icon_html.png";
import css from "./image/icon_css.png";
import js from "./image/icon_javascript.png";
import react from "./image/icon_react.png";
import nodejs from "./image/icon_nodejs.png";
import expressjs from "./image/icon_express-js.png";
import restApi from "./image/icon_restApi.png";
import responsive from "./image/icon_responsive.png";
import firebase from "./image/icon_firebase.png";
import mongodb from "./image/icon_mongodb.png";
import mysql from "./image/icon_mysql.png";
import git from "./image/icon_git.png";
import docker from "./image/icon_docker.png";
import npm from "./image/icon_npm.png";
//images
import coomingSoon from "./image/comonSoonjpg.jpg";
//

const Skill = () => {
  return (
    <>
      <div className="skillMaster">
        <Parallax className="Parallax">
          <div className="p1Div1">
            <Zoom>
              <img className="p1i1" src={skillimg} alt="img"></img>
            </Zoom>
            <pre className="p1pre1">
              In the ever-evolving landscape of computer science, a robust skill
              set is key to success. Here's a glimpse into my toolkit, honed
              through academic endeavors, hands-on experience, and a thirst for
              continuous learning.
            </pre>
          </div>
        </Parallax>
        {/* <Parallax className="Parallax">
          <div className="p2Div1">
            <h1>Programming Languages</h1>
            <div className="p2Div2">
              <div className="plDiv">1</div>
              <div className="plDiv">1</div>
              <div className="plDiv">1</div>
              <div className="plDiv">1</div>
              <div className="plDiv">1</div>
            </div>
          </div>
        </Parallax> */}
        <Parallax className="Parallax">
          <div className="p3Div1">
            <h1>Web Development</h1>
            <div className="wd_Container">
              <Zoom>
                <div className="wd_basics">
                  <h2>Basics</h2>
                  <img
                    title="HTML"
                    className="wd_icons"
                    src={html}
                    alt="img"
                  ></img>
                  <img
                    title="CSS"
                    className="wd_icons"
                    src={css}
                    alt="img"
                  ></img>
                  <img
                    title="Java Scripa"
                    className="wd_icons"
                    src={js}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
              <Zoom>
                <div className="wd_fe">
                  <h2>Front-End</h2>
                  <img
                    title="React JS/TS"
                    className="wd_icons"
                    src={react}
                    alt="img"
                  ></img>
                  <img
                    title="Responsive Design"
                    className="wd_icons"
                    src={responsive}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
              <Zoom>
                <div className="wd_be">
                  <h2>Back-End</h2>
                  <img
                    title="Node JS"
                    className="wd_icons"
                    src={nodejs}
                    alt="img"
                  ></img>
                  <img
                    title="Express JS"
                    className="wd_icons"
                    src={expressjs}
                    alt="img"
                  ></img>
                  <img
                    title="Rest API"
                    className="wd_icons"
                    src={restApi}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
              <Zoom>
                <div className="wd_service">
                  <h2>Services</h2>
                  <img
                    title="Firebase"
                    className="wd_icons"
                    src={firebase}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
            </div>
          </div>
        </Parallax>
        <Parallax className="Parallax">
          <div className="p4Div1">
            <h1>Database Management</h1>
            <div className="p4DB_Container">
              <Zoom>
                <div className="p4Div2">
                  <h2>Relational Database</h2>
                  <img
                    title="MySQL"
                    className="wd_icons"
                    src={mysql}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
              <Zoom>
                <div className="p4Div3">
                  <h2>NonSQL Database</h2>
                  <img title="Mongodb" className="wd_icons" src={mongodb} alt="img"></img>
                  <img
                    title="Firebase"
                    className="wd_icons"
                    src={firebase}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
            </div>
          </div>
        </Parallax>
        <Parallax className="Parallax">
          <div className="p5Div1">
            <h1> others</h1>
            <div className="p5Other_Container">
              <Zoom>
                <div className="p5sd1">
                  <h2>Version Control</h2>
                  <img
                    title="Git"
                    className="wd_icons"
                    src={git}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
              <Zoom>
                <div className="p5sd2">
                  <h2>Containerization</h2>
                  <img
                    title="Docker"
                    className="wd_icons"
                    src={docker}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
              <Zoom>
                <div className="p5sd3">
                  <h2>Package Manager</h2>
                  <img
                    title="NPM"
                    className="wd_icons"
                    src={npm}
                    alt="img"
                  ></img>
                </div>
              </Zoom>
            </div>
          </div>
        </Parallax>
        <Parallax className="Parallax">
          <div className="p7Div1">
            <h1>Certefications</h1>
            <div>
              <img className="p7img1" src={coomingSoon} alt="img"></img>
            </div>
          </div>
        </Parallax>
      </div>
    </>
  );
};

export default Skill;
